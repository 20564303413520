import '../../styles/pages/fall-winter-2022/campaign-highlights.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2022-stories/more-stories"
import Icon from "../../components/icons";

import socialBanner from "../../images/fall-winter-2022/social-banners/campaign-highlights.jpg";


import hero from '../../images/fall-winter-2022/campaign-highlights/hero.jpg'
import itat from '../../images/fall-winter-2022/campaign-highlights/itat.jpg'
import marcDickeyS_800x500 from '../../images/fall-winter-2022/campaign-highlights/Marc-Dickey-S-800x500.jpg'
import facesOfPhilanthropy from '../../images/fall-winter-2022/campaign-highlights/Faces-of-Philanthropy.jpg'
import gwpacGiftGroup2_800x500 from '../../images/fall-winter-2022/campaign-highlights/GWPAC-gift-group2-800x500.jpg'
import tuffyBanner_800x500 from '../../images/fall-winter-2022/campaign-highlights/tuffy-banner-800x500.gif'
import robertHodges2 from '../../images/fall-winter-2022/campaign-highlights/Robert-Hodges2.jpg'
import arboretumSpring_800x500 from '../../images/fall-winter-2022/campaign-highlights/Arboretum-Spring-800x500.jpg'
import centerHealthyNeighborhoods_800x500 from '../../images/fall-winter-2022/campaign-highlights/Center-Healthy-Neighborhoods-800x500.jpg'
import christineMichaelIrwin_800x500 from '../../images/fall-winter-2022/campaign-highlights/Christine-Michael-Irwin-800x500.jpg'
import spring_2020_010_800x500 from '../../images/fall-winter-2022/campaign-highlights/Spring_2020_010-800x500.jpg'
import terryGilesMarina_800x500 from '../../images/fall-winter-2022/campaign-highlights/Terry-Giles-Marina-800x500.jpg'
import nbcGrant_800x500 from '../../images/fall-winter-2022/campaign-highlights/NBC-Grant-800x500.jpg'
import csufCollegeEducationGrantSchoolsFirst_800x500 from '../../images/fall-winter-2022/campaign-highlights/CSUF-College-Education-Grant-Schools-First-800x500.jpg'
import softballBuildingRender_800x500 from '../../images/fall-winter-2022/campaign-highlights/Softball-Building-Render-800x500.jpg'
import womenComputerScience_800x500 from '../../images/fall-winter-2022/campaign-highlights/women-computer-science-800x500.jpg'
import itTakesATitanCampaignUpdate_1_800x500 from '../../images/fall-winter-2022/campaign-highlights/It-Takes-A-Titan-Campaign-Update-1-800x500.jpg'
import springLangsdorf_800x500 from '../../images/fall-winter-2022/campaign-highlights/Spring-Langsdorf-800x500.jpg'
import womenComputerScienceEngineeringProgeam_800x500 from '../../images/fall-winter-2022/campaign-highlights/Women-Computer-Science-Engineering-Progeam-800x500.jpg'
import betteWylieAitken_800x500 from '../../images/fall-winter-2022/campaign-highlights/Bette-Wylie-Aitken-800x500.jpg'
import titansGive_2022_001_800x500 from '../../images/fall-winter-2022/campaign-highlights/Titans_Give_2022_001-800x500.jpg'
import franColwellDonationGregDymentMichaelKarg_800x600_1_800x500 from '../../images/fall-winter-2022/campaign-highlights/Fran-Colwell-Donation-Greg-Dyment-Michael-Karg-800x600-1-800x500.jpg'
import titanSpotlightJill from '../../images/fall-winter-2022/campaign-highlights/titan-spotlight-jill.jpg'
import img3T8A9289_800x500 from '../../images/fall-winter-2022/campaign-highlights/3T8A9289-800x500.jpg'
import img52095305086_4ca0a76a3cO_800x500 from '../../images/fall-winter-2022/campaign-highlights/52095305086_4ca0a76a3c_o-800x500.jpg'

import logo from '../../images/fall-winter-2022/campaign-highlights/campaign-logo.svg';


export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'Your Gift Is Changing Lives';
    var pageDesc = 'A collection of philanthropic stories showcasing how Cal State Fullerton supporters generously answered the call to empower students to reach their highest potential.';
    var slug = 'campaign-highlights';

    var heroStyles = {
      backgroundColor: '#222',
      backgroundImage: `url(${hero})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
      backgroundSize: 'cover',
      color: 'white'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2022">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`fall-winter-2022 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <img src={logo} alt="It Takes a Titan - The Campaign for Cal State Fullerton" className="itat-logo animated fadeIn delay-1s" />
            <h1 className="animated fadeIn delay-1s">{pageTitle}</h1>
            <div className='wrap small'>
              {/* <p className="sub-heading sub-title animated fadeIn delay-1s">A collection of philanthropic stories showcasing how Cal State Fullerton supporters generously answered the call to empower students to reach their highest potential.</p> */}

              <p className="sub-heading animated fadeIn delay-1s">To empower the potential of its students, Cal State Fullerton launched its first-ever comprehensive philanthropic campaign in March 2020. The following collection of stories showcases how university supporters generously answered the call through multiple giving days, legacy gifts and innovative grants.</p>
  
              <p className="sub-heading animated fadeIn delay-1s">What does it take to make a difference in the life of a Cal State Fullerton student?<br /><strong>It takes a Titan like you.</strong> There’s still time to join the effort:</p>
              <a href="https://campaign.fullerton.edu" target="_blank" rel="noreferrer"  className='pill orange animated fadeIn delay-1s'>campaign.fullerton.edu <Icon name="arrow-right" alt="Read Story" class="sm" style={{ verticalAlign: 'middle' }} /></a>
            </div>
          </div>
          <div className="tint"></div>
        </div>
        <article className="wrap">

          <div className="story-list" id="list">


              <ScrollAnimation animateOnce={true} animateIn="fadeInUp" offset={100}>
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/2022/06/11-cal-state-fullerton-programs-awarded-funding-from-scott-jewett-gift/">
                <img src={img3T8A9289_800x500} alt="" />
                <div className="textwrap">
                  <h2>11 Cal State Fullerton Programs Awarded Funding From Scott-Jewett Gift</h2>
                  <p>Cal State Fullerton received a $40 million gift from philanthropists MacKenzie Scott and Dan Jewett to support student success and innovation through the lens of social justice.</p>
                </div>
              </a>
              </ScrollAnimation>

              <ScrollAnimation animateOnce={true} animateIn="fadeInUp" offset={100}>
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/spotlight/35000-donated-to-fullerton-arboretum-for-harry-l-norman-endowment/">
                <img src={titanSpotlightJill} alt="" />
                <div className="textwrap">
                  <h2>$35,000 Donated to Fullerton Arboretum for Harry L. Norman Endowment</h2>
                  <p>Fullerton Arboretum recently received a generous gift commitment of $35,000 from Jill Kurti Norman.</p>
                </div>
              </a>
              </ScrollAnimation>


            <ScrollAnimation animateOnce={true} animateIn="fadeInUp" offset={100}>
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/spotlight/two-generous-gifts-promote-student-engagement-in-fullerton-arboretum/">
                <img src={franColwellDonationGregDymentMichaelKarg_800x600_1_800x500} alt="" />
                <div className="textwrap">
                  <h2>Two Generous Gifts Promote Student Engagement in Fullerton Arboretum</h2>
                  <p>For more than 40 years, Friends of the Fullerton Arboretum has contributed millions of dollars in funding, volunteer services, scholarships and event support.</p>
                </div>
              </a>
              </ScrollAnimation>


            <ScrollAnimation animateOnce={true} animateIn="fadeInUp" delay={50}>
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/2022/03/titansgive-donors-show-overwhelming-support-for-titans-in-fun-filled-day-of-giving/">
                <img src={titansGive_2022_001_800x500} alt="" />
                <div className="textwrap">
                  <h2>#TitansGive Donors Show Overwhelming Support for Titans in Fun-Filled Day of Giving</h2>
                  <p>#TitansGive, the university's annual day of giving, successfully rallied Titans across the globe, generating $491,900 in support of student success.</p>
                </div>
              </a>
              </ScrollAnimation>


            <ScrollAnimation animateOnce={true} animateIn="fadeInUp" delay={100}>
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/2022/01/lawrence-de-graaf-center-for-oral-and-public-history-ribbon-cutting-ceremony/">
                <img src={betteWylieAitken_800x500} alt="" />
                <div className="textwrap">
                  <h2>Donors, Supporters of Lawrence de Graaf Center for Oral and Public History Celebrate at Ribbon-Cutting Ceremony</h2>
                  <p>The Lawrence de Graaf Center for Oral and Public History held a ribbon-cutting ceremony with donors and supporters to celebrate its new space. </p>
                </div>
              </a>
              </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/2021/12/grant-from-bank-of-america-supports-csufs-women-in-computer-science-and-engineering-program/">
                <img src={womenComputerScienceEngineeringProgeam_800x500} alt="" />
                <div className="textwrap">
                  <h2>$325,000 Workforce Development Grant from Bank of America Supports CSUF’s Women in Computer Science and Engineering Program</h2>
                  <p>The university’s Women in Computer Science and Engineering (WiCSE) Program recently received a pivotal Bank of America grant of $325,000.</p>
                </div>
              </a>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeInUp" delay={50}>
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/2021/06/philanthropist-mackenzie-scott-gives-40-million-gift-to-cal-state-fullerton/">
                <img src={springLangsdorf_800x500} alt="" />
                <div className="textwrap">
                  <h2>Philanthropist MacKenzie Scott and Husband Dan Jewett Give $40 Million Gift to Cal State Fullerton</h2>
                  <p>Cal State Fullerton has received its largest gift in history: $40 million from philanthropist MacKenzie Scott.</p>
                </div>
              </a>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeInUp" delay={100}>
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/2021/03/650000-grant-to-attract-retain-and-graduate-women-in-computer-science/">
                <img src={womenComputerScience_800x500} alt="" />
                <div className="textwrap">
                  <h2>$650,000 Grant Promotes Women in Computer Science</h2>
                  <p>This grant will help remove barriers and increase the number of women graduating in computer science.</p>
                </div>
              </a>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/2021/03/universitys-day-of-giving-raises-more-than-450000/">
                <img src={itTakesATitanCampaignUpdate_1_800x500} alt="" />
                <div className="textwrap">
                  <h2>University’s Day of Giving Raises More Than $450,000</h2>
                  <p>Thousands of Titans joined together March 9 for #TitansGive, which successfully raised more than $450,000 for the university’s “It Takes a Titan” campaign.</p> 
                </div>
              </a>
              </ScrollAnimation>

            {/* <ScrollAnimation animateOnce={true} animateIn="fadeInUp" delay={50}>
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/2021/03/650000-grant-to-attract-retain-and-graduate-women-in-computer-science/">
                <img src={womenComputerScience_800x500} alt="" />
                <div className="textwrap">
                  <h2>$650,000 Grant to Attract, Retain and Graduate Women in Computer Science</h2>
                  <p>To address the gender gap, remove barriers and increase the number of women graduating in computer science at Cal State Fullerton, the university has been To address the gender gap, remove barriers and increase the number of women graduating in computer science at Cal State Fullerton, the university has been awarded a $650,000 “Best Practice” grant.</p>
                </div>
              </a>
              </ScrollAnimation> */}

            <ScrollAnimation animateOnce={true} animateIn="fadeInUp" delay={100}>
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/2021/04/softball-dad-hits-it-out-of-the-park-with-gift-to-championship-program/">
                <img src={softballBuildingRender_800x500} alt="" />
                <div className="textwrap">
                  <h2>‘Softball Dad’ Hits it Out of the Park With Gift to Championship Program</h2>
                  <p>The Cervantes family has pledged a $75,000 gift to the softball program, with an additional $50,000 match to name the renovated clubhouse patio deck "The Cervantes Family Terrace."</p>
                </div>
              </a>
              </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/2021/03/schoolsfirst-fcu-donates-800000-to-enhance-support-for-teachers/">
                <img src={csufCollegeEducationGrantSchoolsFirst_800x500} alt="" />
                <div className="textwrap">
                  <h2>SchoolsFirst FCU Donates $800,000 to Enhance Support for Teachers</h2>
                  <p>The gift supports the center's outreach, advising, workshops and teacher preparation programs like Titan Future Teachers and Men of Color in Education.</p>
                </div>
              </a>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeInUp" delay={50}>
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/2021/01/500000-grant-from-nbcuniversal-to-advance-training-of-diverse-journalists/">
                <img src={nbcGrant_800x500} alt="" />
                <div className="textwrap">
                  <h2>$500,000 Grant From NBCUniversal to Advance Training of Diverse Journalists</h2>
                  <p>Cal State Fullerton is the only four-year university in California partnering with NBCUniversal to train underrepresented journalism students.</p>
                </div>
              </a>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeInUp" delay={100}>
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/2021/02/alumnus-commits-1-million-gift-to-center-for-leadership/">
                <img src={terryGilesMarina_800x500} alt="" />
                <div className="textwrap">
                  <h2>Alumnus Commits $1 Million Gift to Center for Leadership</h2>
                  <p>Terry M. Giles — lawyer, businessman, author and longtime CSUF supporter — has pledged a $1 million gift to the Center for Leadership.</p>
                </div>
              </a>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/2021/01/alumni-association-steps-up-with-45000-grant-to-help-current-students/">
                <img src={spring_2020_010_800x500} alt="" />
                <div className="textwrap">
                  <h2>Alumni Association Steps Up With $45,000 Grant to Help Current Students</h2>
                  <p>When the pandemic sent tens of thousands of students home to complete their classes virtually, the Titan Alumni Association stepped up to help.</p>
                </div>
              </a>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeInUp" delay={50}>
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/2021/01/rock-solid-csuf-education-inspires-couple-to-support-future-geologists/">
                <img src={christineMichaelIrwin_800x500} alt="" />
                <div className="textwrap">
                  <h2>Rock Solid CSUF Education Inspires Couple to Support Future Geologists</h2>
                  <p>Cal State Fullerton geology alumni and longtime supporters of the Department of Geological Sciences pledge a $400,000 legacy gift to support geology students in their academic endeavors.</p>
                </div>
              </a>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeInUp" delay={100}>
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/2021/02/tarsadia-foundation-gifts-300000-to-center-for-healthy-neighborhoods/">
                <img src={centerHealthyNeighborhoods_800x500} alt="" />
                <div className="textwrap">
                  <h2>Tarsadia Foundation Gifts $300,000 to Center for Healthy Neighborhoods</h2>
                  <p>The Tarsadia Foundation recently committed a $300,000 gift to the Center for Healthy Neighborhoods to support its three-year strategic plan.</p>
                </div>
              </a>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/2021/02/fullerton-arboretum-plants-survive-and-thrive-thanks-to-100000-matching-grant/">
                <img src={arboretumSpring_800x500} alt="" />
                <div className="textwrap">
                  <h2>Fullerton Arboretum Plants ‘Survive and Thrive’ Thanks to $100,000 Matching Grant</h2>
                  <p>A matching grant from the Mysun Charitable Foundation helps bring the Fullerton Arboretum’s horticulture team back to full-time work.</p>
                </div>
              </a>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeInUp" delay={50}>
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/2020/06/robert-r-hodges-fellowship-endowment/">
                <img src={robertHodges2} alt="" />
                <div className="textwrap">
                  <h2>Robert R. Hodges Fellowship Endowment Established</h2>
                  <p>An endowment of $400,000 in the name of Robert Hodges, professor emeritus of English, has been established to support fellowships for students pursuing academic degrees in English or comparative literature.</p>
                </div>
              </a>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeInUp" delay={100}>
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/2020/03/day-of-giving-csuf/">
                <img src={tuffyBanner_800x500} alt="" />
                <div className="textwrap">
                  <h2>Titans Give Generously on University’s First Day of Giving</h2>
                  <p>Hundreds of Titans across the globe rallied together March 12 to make #TitansGive, Cal State Fullerton’s first-ever day of giving, an unqualified success.</p>
                </div>
              </a>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/2020/02/campaign-gift-begovich/">
                <img src={gwpacGiftGroup2_800x500} alt="" />
                <div className="textwrap">
                  <h2>Longtime University Donors Commit to $10 Million Gift of Titan Proportions</h2>
                  <p>Fullerton couple and university supporters Nicholas and Lee Begovich have committed to a $10 million planned gift to Cal State Fullerton to benefit gravitational-wave, engineering and computer science faculty and student research.</p>
                </div>
              </a>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeInUp" delay={50}>
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/feature/faces-of-philanthropy/">
                <img src={facesOfPhilanthropy} alt="" />
                <div className="textwrap">
                  <h2>Faces of Philanthropy</h2>
                  <p>Every gift to Cal State Fullerton – no matter the size or type – has the potential to transform a student’s life. Donors to the university’s “It Takes a Titan” campaign share why they chose to give, and how they personalized their gifts in a way that was meaningful to them.</p>
                </div>
              </a>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeInUp" delay={100}>
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/2021/03/music-professor-tops-noteworthy-career-with-a-gift-for-future-teachers/">
                <img src={marcDickeyS_800x500} alt="" />
                <div className="textwrap">
                  <h2>Music Professor Tops Noteworthy Career With a Gift for Future Teachers</h2>
                  <p>Nearing retirement, Professor of Music Marc Dickey established the Willard and Virginia Dickey Instrumental Music Education Student Teacher Scholarship at Cal State Fullerton.</p>
                </div>
              </a>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
              <a target="_blank" rel="noreferrer"  className="story"  href="https://news.fullerton.edu/2020/03/it-takes-a-titan-campaign-launch/">
                <img src={itat} alt="" />
                <div className="textwrap">
                  <h2>Campus to Launch ‘It Takes a Titan’ Campaign March 12</h2>
                  <p>The campus is launching its first-ever comprehensive philanthropic campaign, "It Takes a Titan," Thursday, March 12, 9-11 a.m. in Titan Student Union Portola Pavilion.</p>
                </div>
              </a>
            </ScrollAnimation>


          </div>

        </article>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 